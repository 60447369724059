<template>
  <div>
    <!-- <div class="container-sm text-center neon d-xl-none d-md-none"> -->
    <div class="text-center neon">
      <div class="bottombar-mobile">
        <div class="container">
          <ul class="nav-menu">
            <li class="nav-item">
              <router-link :to="{ name: 'home' }">
                <a
                  class="nav-link"
                ><i class="fad fa-home" />
                  <span>หน้าหลัก</span>
                </a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'deposit' }"
                class="nav-link"
              ><i class="fad fa-arrow-down" /><span>เติมเงิน</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'lottery' }"
                class="nav-link"
              >
                <i class="fad fa-edit" />

                <span>แทงหวย</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'withdraw' }"
                class="nav-link"
              >
                <i class="fad fa-arrow-up" />
                <span>ถอนเงิน</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'profile' }"
                class="nav-link"
              >
                <i class="fab fa-line" /><span>ติดต่อเรา</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    // BRow,
    // BCol,
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
  },
}
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
.container-sm {
  max-width: 540px;
}
.neon {
  /* font-family: 'Helvethaica', sans-serif; */
  background: #0b0e11;
  padding: 10px 10px 0px 5px;
}
.m-space {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 35px;
  margin-left: 1px;
  margin-right: 1px;
}
.bf {
  background-image: url(/iconJK/game.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
span {
  font-size: 18px;
}
.bottombar-mobile {
  background: #414141;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  display: none;
  /* box-shadow: 0px -4px 20px rgba(218, 218, 218, 0.75); */
}
@media screen and (max-width: 2400px) {
  .bottombar-mobile {
    display: block;
  }
}
.bottombar-mobile .nav-menu {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-around;
}
.bottombar-mobile .nav-menu .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  list-style-type: none;
  text-align: center;
}
.bottombar-mobile .nav-menu .nav-item.middle-item span {
  font-size: 1rem;
  /* margin-top: 3px; */
  color: rgb(255, 255, 255);
  display: block;
}
.a {
  opacity: 1;
  border-radius: 50%;
  position: relative;
  color: #ffffff;
  width: 60px;
  height: 60px;
  margin: -1rem auto auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
  padding: 0;
  background: rgb(255, 247, 1);
  background: linear-gradient(
    22deg,
    rgba(255, 247, 1, 1) 0%,
    rgba(255, 8, 1, 1) 100%
  );
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 214, 34, 0.808);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(202, 170, 25, 0.281);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 193, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 167, 44, 0.815);
    box-shadow: 0 0 0 0 rgba(204, 167, 44, 0.815);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 44, 183, 0);
    box-shadow: 0 0 0 10px rgba(204, 44, 183, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
.bottombar-mobile .nav-menu .nav-item.middle-item .nav-link {
  opacity: 1;
  border-radius: 50%;
  position: relative;
  color: #ffffff;
  width: 60px;
  height: 60px;
  margin: -1rem auto auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  padding: 0;
  /* background: rgb(255, 247, 1);
  background: linear-gradient(
    22deg,
    rgba(255, 247, 1, 1) 0%,
    rgba(255, 8, 1, 1) 100%
  ); */
  border: 3px solid transparent;
  background: linear-gradient(180deg, #d66d43 0%, #d61c56 100%) padding-box,
    /*this is your grey background*/
      linear-gradient(to right, #bf953f, #fcf6ba, #b38728, #fbf5b7, #aa771c)
      border-box;
  position: relative;
}
.bottombar-mobile .nav-menu .nav-item.middle-item .nav-link i {
  position: relative;
  line-height: 1;
  font-size: 28px;
  color: rgb(255, 255, 255);
  display: flex;
  align-content: center;
}
.bottombar-mobile .nav-menu .nav-item .nav-link {
  text-decoration: none;
  padding: 0.25rem 0;
  opacity: 0.75;
  color: rgb(255, 255, 255);
  display: block;
}
.bottombar-mobile .nav-menu .nav-item .nav-link.active {
  opacity: 1;
}
.bottombar-mobile .nav-menu .nav-item .nav-link i {
  display: block;
  line-height: 1.8;
  font-size: 1.2rem;
  color: #ffbb00;
}
.bottombar-mobile .nav-menu .nav-item .nav-link span {
  font-size: 1rem;
  line-height: 1.5;
  display: block;
}
i {
  display: block;
  line-height: 1.2;
  color: #accb32;
}
</style>
